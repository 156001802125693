export const reduceVencimientos = async (entidad, identificacion) => {
    return await entidad?.reduce((vencimientos, objeto) => {
        if (!objeto.documentos) {
            return vencimientos;
        } else {
            const data = Object.entries(objeto.documentos).map(([key, value]) => {
                return { title: `${objeto[identificacion]} (${key})`, start: value.vencimiento || null };
            });

            return [...vencimientos, ...data];
        }
    }, []);
};
