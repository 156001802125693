import logisticaApi from "@/modules/logistica/helpers/logisticaApi";
import apiConsumos from "@/api/apiConsumos";

export const loadDocumentsModels = async ({ commit }) => {
    try {
        const { data } = await logisticaApi.get("/documentos.json");

        console.log(' ')
        console.log(' :D logisticaApi.get("/documentos.json")', data)
        console.log(' ')

        if (data) {
            commit(
                "setDocumentsModels",
                Object.entries(data).map(([id, datos]) => ({ id, ...datos }))
            );
        } else {
            console.warn("No data found for /documentos.json");
        }
    } catch (error) {
        console.error("Error loading documents models: ", error);
    }
};

export const loadEntidades = async ({ commit }) => {
    try {
        const { data: empresas } = await logisticaApi.get("/empresas.json");
        const { data: choferes } = await logisticaApi.get("/choferes.json");
        const { data: vehiculos } = await logisticaApi.get("/vehiculos.json");

        if (empresas) {
            commit(
                "setEmpresasProveedoras",
                Object.entries(empresas).map(([id, datos]) => ({ id, ...datos }))
            );
        } else {
            console.warn("No empresas data found");
        }
        
        if (choferes) {
            commit(
                "setChoferes",
                Object.entries(choferes).map(([id, datos]) => ({ id, ...datos }))
            );
        } else {
            console.warn("No choferes data found");
        }

        if (vehiculos) {
            commit(
                "setVehiculos",
                Object.entries(vehiculos).map(([id, datos]) => ({ id, ...datos }))
            );
        } else {
            console.warn("No vehiculos data found");
        }
    } catch (error) {
        console.error("Error loading entidades: ", error);
    }
};

export const loadEmpresas = async ({ commit }) => {
    try {
        const { data } = await apiConsumos.get("/empresas");
        if (data) {
            commit("setEmpresas", data);
        } else {
            console.warn("No empresas data found from apiConsumos");
        }
    } catch (error) {
        console.error("Error loading empresas: ", error);
    }
};

export const cargarEntidadesLogisticas = async ({ commit }) => {
    try {
        const { data } = await logisticaApi.get("/vinculos.json");
        if (data) {
            commit(
                "setEntidadesLogisticas",
                Object.entries(data).map(([id, datos]) => ({ id, ...datos }))
            );
        } else {
            console.warn("No vinculos data found");
        }
    } catch (error) {
        console.error("Error loading entidades logisticas: ", error);
    }
};

export const updateStorageUnit = ({ commit }, item) => {
    commit("updateStoraUnit", item);
};

export const addStorageUnit = ({ commit }, item) => {
    commit("addStoraUnit", item);
};

export const deleteStorageUnit = ({ commit }, item) => {
    commit("deleteStorageUnit", item);
};

export const loadRutas = async ({ commit }) => {
    try {
        const { data } = await logisticaApi.get("/routes.json");
        if (data) {
            commit(
                "setRutas",
                Object.entries(data).map(([id, datos]) => ({ id, ...datos }))
            );
        } else {
            console.warn("No routes data found");
        }
    } catch (error) {
        console.error("Error loading rutas: ", error);
    }
};
