import auth from "@/modules/auth/store/auth";
import logistica from "@/modules/logistica/store/logistica";
import { createStore } from "vuex";


const store = createStore({
    modules: {
        auth,
        logistica
    }
})

export default store