export const setDocumentsModels = (state, data) => {
    state.documentsModels = data;
};

export const setEmpresasProveedoras = (state, data) => {
    state.empresasProveedoras = data;
};

export const setChoferes = (state, data) => {
    state.choferes = data;
};

export const setVehiculos = (state, data) => {
    state.vehiculos = data;
};

export const setEmpresas = (state, data) => {
    state.empresas = data;
};

export const setEntidadesLogisticas = (state, data) => {
    state.entidadesLogisticas = data;
};

export const setCapacidadDeCargaDisponible = (state, data) => {
    state.capacidadDeCargaDisponible = data;
};

export const updateStoraUnit = (state, storageUnit) => {
    const storageUnitIndex = state.storageUnits.findIndex(
        (unit) => unit.id === storageUnit.id
    );
    if (storageUnitIndex !== -1) {
        state.storageUnits[storageUnitIndex].factor_conversion = parseInt(
            storageUnit.factor_conversion
        );
    }
};

export const addStoraUnit = (state, storageUnit) => {
    state.storageUnits.push({
        id: state.storageUnits.length + 1,
        ...storageUnit,
    });
};

export const deleteStorageUnit = (state, storageUnit) => {
    const storageUnitIndex = state.storageUnits.findIndex(
        (unit) => unit.id === storageUnit.id
    );
    state.storageUnits.splice(storageUnitIndex, 1);
};

export const setRutas = (state, data) => {
    state.rutas = data;
};
