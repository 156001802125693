import { createRouter, createWebHashHistory } from "vue-router";
import logisticaRouter from "../modules/logistica/router";
import isAutenticatedGuard from "./logistica-guard";

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "Login"*/ "@/modules/auth/views/Login"),
    },
    {
        path: "/logistica",
        ...logisticaRouter,
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(isAutenticatedGuard);

export default router;
