import axios from "axios";

// const url = 'https://be-foodservice-logistcs-default-rtdb.firebaseio.com/'
// const url = 'https://logisticabyomi-default-rtdb.firebaseio.com'
const url= process.env

const logisticaApi = axios.create({
    baseURL: url
})

export default logisticaApi