import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vue3EasyDataTable from 'vue3-easy-data-table';

import 'vue3-easy-data-table/dist/style.css';
import '@/styles/styles.css'


// import 'jquery/dist/jquery.min.js'

// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// createApp(App).use(BootstrapVue);
// createApp(App).use(IconsPlugin);


// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

createApp(App)
    .use(store)
    .use(router)
    .component('EasyDataTable', Vue3EasyDataTable)
    .mount('#app')
