import axios from 'axios';
import moment from "moment/moment";
import { reduceVencimientos } from "../../helpers/getVencimientos";
import firebase from "firebase/app";
import "firebase/database";

export const getDocumentsModels = (state) => {
    const documentsModels = state.documentsModels;
    return documentsModels;
};

export const getDocumentsModelsWithFilter =
    (state) =>
    (filter = []) => {
        const filteredDocumentsModels = state.documentsModels.filter((doc) => filter.includes(doc.nombre));

        return filteredDocumentsModels;
    };

export const getEntidad = (state) => (entidad) => {
    const data = state[entidad];

    return data;
};

export const getEmpresasByRoute = async (date, route) => {
    moment.locale("es");

    try {
        const response = await axios.get('https://consumos.foodservice.com.ar/api/empresas');
        const data = response.data;

        console.log(':D Data from API: ', date, route, data);

        const dayWeek = `ruta_${moment(date).format("dddd")}`;
        const enterpriseByRoute = data.filter((enterprise) => enterprise[dayWeek.normalize("NFD").replace(/[\u0300-\u036f]/g, "")] === parseInt(route, 10));

        console.log(':D enterpriseByRoute: ', enterpriseByRoute);
        return enterpriseByRoute;
    } catch (error) {
        console.error('Error fetching data from API: ', error);
        return [];
    }
};

export const getServicesSortByName = (state) => () => {
    const data = state.empresas;

    data.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    return data;
};

export const getUnidadesAlmacenamiento = (state) => () => {
    const data = state.unidadesAlmacenamiento;

    return data;
};

// export const getEntidadesLogisticas = (state) => () => {
//     const data = state.entidadesLogisticas;

//     return data;
// };

export const getEntidadesLogisticas = async () => {
    try {
        // Referencia a la colección 'vinculos' en Firebase Realtime Database
        const snapshot = await firebase.database().ref('vinculos').once('value');
        
        // Si hay datos, conviértelos en un array, de lo contrario, devuelve un array vacío
        const data = [];
        snapshot.forEach(childSnapshot => {
            const id = childSnapshot.key;
            const childData = childSnapshot.val();
            data.push({
                id: id,
                ...childData
            });
        });

        console.log(':D getEntidadesLogisticas(data): ', data);
        return data;
    } catch (error) {
        console.error("Error obteniendo los documentos desde Firebase: ", error);
        return [];
    }
};

export const getCapacidadDeCargaDisponible = (state) => () => {
    const data = state.capacidadDeCargaDisponible;

    return data;
};

export const getStorageUnits = (state) => () => {
    const data = state.storageUnits;
    return data;
};

export const getRutas = (state) => () => {
    const data = state.rutas;
    return data;
};

export const getRutasById = (state) => (id) => {
    const data = state.rutas;
    return data.filter((ruta) => ruta.id === id)[0];
};


export const getVencimientos = async (entidad) => {
    const refPath = entidad === "empresasProveedoras"
        ? "empresasProveedoras"
        : entidad === "choferes"
        ? "choferes"
        : "vehiculos";

    const identificacion = entidad === "empresasProveedoras"
        ? "nombre"
        : entidad === "choferes"
        ? "apellido"
        : "dominio";

    try {
        // Obtén los datos desde Firebase
        const snapshot = await firebase.database().ref(refPath).once('value');
        const datosEntidad = snapshot.val() ? Object.values(snapshot.val()) : [];

        // Procesa los datos obtenidos para extraer los vencimientos
        const vencimientos = await reduceVencimientos(datosEntidad, identificacion);

        console.log(' ')
        console.log(':D getVencimientos-> Vencimientos', vencimientos)
        console.log(' ')

        return vencimientos;
    } catch (error) {
        console.error("Error obteniendo los documentos desde Firebase: ", error);
        return [];
    }
};
