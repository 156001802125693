<template>
    <nav class="sidebar">
        <div class="sidebar-header">
            <a href="" class="sidebar-brand"> <span>FS</span> LOGISTICA </a>
            <div class="sidebar-toggler not-active">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <div class="sidebar-body">
            <ul class="nav">
                <li class="nav-item">
                    <router-link :to="{ name: 'dashboard' }" class="nav-link">
                        <i class="link-icon" data-feather="home"></i>
                        <span class="link-title">Inicio</span>
                    </router-link>
                </li>
                <li class="nav-item nav-category">Aplicativos</li>
                <li class="nav-item">
                    <router-link :to="{ name: 'admision' }" class="nav-link">
                        <i class="link-icon" data-feather="alert-octagon"></i>
                        <span class="link-title">Admision</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'rutas' }" class="nav-link">
                        <i class="link-icon" data-feather="map-pin"></i>
                        <span class="link-title">Rutas</span>
                    </router-link>
                </li>
                <li class="nav-item nav-category">Proveedores</li>
                <li class="nav-item">
                    <router-link :to="{ name: 'empresas' }" class="nav-link">
                        <i class="link-icon" data-feather="box"></i>
                        <span class="link-title">Empresas</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{ name: 'choferes' }" class="nav-link">
                        <i class="link-icon" data-feather="user"></i>
                        <span class="link-title">Choferes</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{ name: 'vehiculos' }" class="nav-link">
                        <i class="link-icon" data-feather="truck"></i>
                        <span class="link-title">Vehiculos</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{ name: 'entidades-logisticas' }" class="nav-link">
                        <i class="link-icon" data-feather="link"></i>
                        <span class="link-title">Entidades logísticas</span>
                    </router-link>
                </li>

                <li class="nav-item">
                    <router-link :to="{ name: 'calendario-vencimientos' }" class="nav-link">
                        <i class="link-icon" data-feather="calendar"></i>
                        <span class="link-title">Vencimientos</span>
                    </router-link>
                </li>

                <li class="nav-item nav-category">Configuración</li>
                <li class="nav-item">
                    <router-link :to="{ name: 'documentos' }" class="nav-link">
                        <i class="link-icon" data-feather="clock"></i>
                        <span class="link-title">Documentos</span>
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link :to="{ name: 'unidades-almacenaje' }" class="nav-link">
                        <i class="link-icon" data-feather="shopping-bag"></i>
                        <span class="link-title">Unidades de Almacenaje</span>
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {
    head: {
        script: [{ type: "text/javascript", src: "<%= BASE_URL %>assets/js/template.js", async: true, body: true }],
    },
};

/*
const userPermission = JSON.parse(JSON.parse(sessionStorage.getItem('userPermisos')));


export default {
  name: 'Menu',
    methods:{
     isRoll(modulo){
      
        var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
        return status;
      }
  }
};

*/
</script>
