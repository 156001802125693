import authApi from "@/api/authApi"
// import Swal from "sweetalert2";
import validateUser from "../../helpers/validateUser";

export const loadUser = async ({ commit }, {path, pass}) => {
    commit('setIsAutenticating', true)
    const { data: [user] }  = await authApi.get(path)

    const userAuth = validateUser(user, pass)

    if (userAuth) {
        commit('setUser', user)
        commit('setIsAutenticated', true)
        commit('setIsAutenticating', false)
        
        return true        
    }

    return false
}