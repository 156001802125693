import store from "@/store";

const isAutenticatedGuard = async (to, from, next) => {
    const isAutenticated = store.state.auth.isAutenticated;

    if (!isAutenticated && to.name !== "login") {
        next({ name: "login" });
    } else {
        next();
    }
};

export default isAutenticatedGuard;
