import Swal from "sweetalert2";


const validateUser = (user, pass) => {

    if (!user || user.Password[0].contrasena !== pass) {
        Swal.fire(
            "Datos incorrectos",
            "Verifique sus datos de acceso",
            "error"
        );
        return false
    } else if ( !user.Permisos.some((p)=>p.ModulosAccess === 'Logistica') ) {
        Swal.fire(
            "Acceso denegado",
            `No tienes los permisos necesarios para acceder a Logística`,
            "error"
        );
        sessionStorage.clear();
        return false
    } else {
        sessionStorage.setItem( "userPermisos", JSON.stringify( user.Permisos ) )
        sessionStorage.setItem( "userData", JSON.stringify(user.datos) )
        sessionStorage.setItem( "userId", JSON.stringify(user.id) )
        return true
    }

}

export default validateUser