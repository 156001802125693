<template>
  <div v-show="isLoginPage">
      <router-view/>
  </div>
  <div id="app" v-show="!isLoginPage">
    <div class="main-wrapper">
      <Menu />
      <div class="page-wrapper">
        <Navbar />
            <router-view/>
      </div>
    </div>
  </div>

</template>

<script>
// import { firebaseConfig, firebaseLogin } from "@/env";
import { firebaseConfig } from "@/env";
// import axios from "axios";

// import $ from "jquery";

import Menu from "@/modules/logistica/components/Menu/Menu.vue";
import Navbar from "@/modules/logistica/components/Navbar/Navbar.vue";

import firebase from "firebase/app";
import "firebase/database";
// import Swal from "sweetalert2";

// // DataTable funciona en todas la demás páginas
// // require("datatables.net");
// // require("datatables.net-buttons/js/buttons.colVis.js")();
// // require("datatables.net-buttons/js/buttons.html5.js")();
// // require("datatables.net-buttons/js/buttons.flash.js")();
// // require("datatables.net-buttons/js/buttons.print.js")();

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// var fireLogin = firebase.initializeApp(firebaseLogin, 'fireLogin');


// // const userData = JSON.parse(sessionStorage.getItem("userData"));
// const userData = JSON.parse(sessionStorage.getItem('userData'));
// let plataforma = "Logistica";


export default {
    name: 'app',
    components:{
        Menu,
        Navbar,
    },
    computed: {
        isLoginPage() {
            return this.$route.path === '/login' ? true : false 
        }
    },

    created() {
      const userData = sessionStorage.getItem("userData")
      if (userData) {
        this.$store.commit('auth/setIsAutenticated', true)
      } else {
        sessionStorage.clear()
        this.$store.commit('auth/deleteUser')
      }
      
    }
//     data: () => ({
//       logged: false,
//     }),
//     methods: {
//           isLector(modulo){
            
//                 if (JSON.parse(sessionStorage.getItem('userPermisos')).find( record => record.ModulosAccess === modulo)) {
//                   var status =  JSON.parse(sessionStorage.getItem('userPermisos')).find( record => record.ModulosAccess === modulo).Lector;
//                   // console.log(modulo+' : '+status)
//                   return status;
//                 }else{
//                   console.log(modulo+' : '+false)
//                   return false;
//                 }
//             },
//       isLogged() {

//         userData
//           ? this.logged = true
//           : this.logged = false

//           if (this.logged) {
//             sessionStorage.clear();
//             this.$router.push({ path: '/' })



//                             axios
//                         .get('https://auth.foodservice.com.ar/?type=login&user='+userData.leg_numdoc+'&access_token=1234567')
//                         .then(response => {
//                             console.log("ok")
//                                sessionStorage.setItem('userPermisos', JSON.stringify(response.data[0].Permisos))
//                              sessionStorage.setItem('userData', JSON.stringify(response.data[0].datos))
//                              sessionStorage.setItem('userId', response.data[0].id)
//                               const uId = sessionStorage.getItem('userId');

//                             if (this.isLector(plataforma)==false) {
//                               Swal.fire('Acceso denegado', 'No tienes los permisos necesarios para acceder a Genesis','error');
//                               sessionStorage.clear();
//                               location.reload();
//                             }
                            
//                              var correop = "";
//                              var oculto ="";
                            
//                              if (response.data[0].Email_de_recupero[0].correo!=null) {
//                                 correop = response.data[0].Email_de_recupero[0].correo;
//                                 oculto ="style='display:none;'";
//                              }

//                              console.log(response.data[0].Password[0].contrasena)

//                              if (response.data[0].Password[0].contrasena==null) {

//                                      (async function(){

//                                            await Swal.fire({
//                                             title: 'Ingrese una nueva contraseña',
//                                             html:
//                                             '<form action="#" id="searchForm">'+
//                                               '<input required type="email" id="correo1" class="swal2-input" placeholder="Email de recupero" value="'+correop+'" '+oculto+' autocomplete="off"><span style="color:red;font-size:14px;display:none;" id="errormail">Email no coincide</span><input autocomplete="off" required type="email" id="correo2" class="swal2-input" placeholder="Repita Email de recupero" value="'+correop+'" '+oculto+'><input autocomplete="off" required type="password" id="pass1" class="swal2-input" placeholder="Nueva contraseña"  ><span style="color:red;font-size:14px;display:none;" id="errorpass">Password no coincide</span><input autocomplete="off" required type="password" id="pass2" class="swal2-input" placeholder="Repita Nueva contraseña"><button type="button" id="guardar" class="swal2-confirm swal2-styled" aria-label="" style="display: inline-block;">Guardar</button></form>',
//                                                showCancelButton: false,   
//                                                showConfirmButton: false, 
//                                                 didOpen(){
//                                                   window.$("#guardar").click(function(){
                                                     

//                                                     if(window.$("#correo1").val()=="") {
//                                                       console.log("vacio")
//                                                     }else if(window.$("#correo2").val()=="") {
//                                                       console.log("vacio")
//                                                     }else if(window.$("#pass1").val()=="") {
//                                                       console.log("vacio")
//                                                     }else if(window.$("#pass2").val()=="") {
//                                                       console.log("vacio")
//                                                     }else{

//                                                       var correo1 = window.$("#correo1").val();
//                                                       var correo2 = window.$("#correo2").val();

//                                                       var pass1 = window.$("#pass1").val();
//                                                       var pass2 = window.$("#pass2").val();

//                                                       if (correo1!=correo2) {
//                                                         window.$("#errormail").slideDown();
//                                                       }else if(pass1!=pass2){
//                                                         window.$("#errorpass").slideDown();
//                                                       }else{
                                                        
                                                       

//                                                         window.$("#errorpass").hide();
//                                                         window.$("#errormail").hide();
//                                                         // var info;

//                                                         if (correop==null) {



//                                                             fireLogin.database().ref('password/' + uId +'').update({
//                                                               contrasena:pass1

//                                                             })
//                                                             .then(()=>{




//                                                          fireLogin.database().ref('Email_de_recupero/' + uId +'').update({
//                                                                                   correo:correo1

//                                                                                 })
//                                                                                 .then(()=>{
//                                                                                   Swal.close();
//                                                                                   .fire('Datos Guardados', 'Felicitaciones!, ya puedes utilizar '+plataforma,'success');
//                                                                                 })
//                                                                                 .catch(e => {
//                                                                                   console.log(e);
//                                                                                 });


                                                             
//                                                             })
//                                                             .catch(e => {
//                                                               console.log(e);
//                                                             });

                                                         

//                                                         }else{

//                                                            fireLogin.database().ref('password/' + uId +'').update({
//                                                               contrasena:pass1

//                                                             })
//                                                             .then(()=>{


//                                                               Swal.close();
//                                                                                   Swal.fire('Datos Guardados', 'Felicitaciones!, ya puedes utilizar '+plataforma,'success');


                                                             
//                                                             })
//                                                             .catch(e => {
//                                                               console.log(e);
//                                                             });


//                                                         }

                                                   



                                                      


//                                                       }

//                                                         if (correo1==correo2) {
//                                                           window.$("#errormail").hide();
//                                                         }

//                                                         if(pass1==pass2){
//                                                           window.$("#errorpass").hide();
//                                                         }


//                                                     }

                                                    
//                                                   })
//                                                }
                                           


//                                             })

//                                     })();



//                              }
                          
                               
//                             })
//                         .catch(error => {console.log(error);});


//            setTimeout(function(){
//             $("#correo1").val("");
//             $("#pass1").val("");
//            },1000)


           


//           }else{
//             this.$router.push('/Login')
//           }
//       },
//       logout() {
//         sessionStorage.removeItem('userData')
       

//         this.$router.push('/login')
//       },
     
//     },
//     computed: {
//       loggedUser() {
//         const token = sessionStorage.getItem('userData')
//         const payload = JSON.parse(atob(token.split('.')[1]))
//         console.log(payload)
//         return payload
//       }
//     },
//     mounted() {
//       this.isLogged()

//     }

};
</script>

<style>
#loadinga {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #efefef;
  z-index: 999999999;
}

#logotipo {
  width: 400px;
  margin-left: 35%;
  margin-top: 10%;
}

.form-control {
	display: inline-block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 1.75rem 0.375rem 0.75rem;
	font-size: .8rem;
	font-weight: 400;
	line-height: 1.5;
	color: #495057;
	vertical-align: middle;
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.custom-select{
    font-size: .8rem;
}

/* A continuación se pisan los estilos de la plantilla para marcar los links activos */

.sidebar .sidebar-body .nav .nav-item.active .nav-link::before {
  content: none;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link{
  color: #000;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link .link-icon {
  color: #000;
}

/* Luego se agregan propiedades similares a las pisadas para que Vue marque los activos mediante el router */

.router-link-exact-active{
  /* font-weight: 700; */
  color: #727cf5 !important;
}

.router-link-exact-active .link-icon{
  /* font-weight: 700; */
  color: #727cf5 !important;
}

.router-link-exact-active::before {
  content: '';
  width: 3px;
  height: 26px;
  background: #727cf5;
  position: absolute;
  left: -25px;
}


/* input group */
.input-group-append .input-group-text, .input-group-prepend .input-group-text {
    padding: 0.5rem 0.75rem;
    color: #fff;
  border: #282f3ae6 !important;
}

.input-group-text {
    background-color: rgb(114, 124, 245);
}

.input-group-prepend .input-group-text {
  border-radius: 0.25rem 0 0 0.25rem;
}
.input-group-append .input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
}

/* Multiselect */
.multiselect__tags {
    border-color: #282f3ae6 !important;
}

.multiselect__content-wrapper {
    border-color: rgba(40, 47, 58, 0.9) !important 

}

.multiselect__tag-icon::after {
    color: rgb(253, 253, 253) !important;
}

.multiselect__tag {
    background: #4e5bf2 !important;
}

/* input select */

select.form-control, select, .email-compose-fields .select2-container--default select.select2-selection--multiple, .select2-container--default select.select2-selection--single, .select2-container--default .select2-selection--single select.select2-search__field, select.typeahead, select.tt-query, select.tt-hint {
    
    color: #282f3ae6 !important;
}

.table-title {
  position: relative;
  top: 24px;
}

</style>