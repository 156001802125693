

export const setUser = ( state, user ) => {
    state.user = user
    state.isAutenticated = true
}

export const setIsAutenticating = (state, value) => {
    state.isAutenticating = value
}

export const setIsAutenticated = (state, value) => {
    state.isAutenticated = value
}

export const deleteUser = ( state ) => {
    state.user = {}
    state.isAutenticated = false
}