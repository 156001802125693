export default () => ({
    documentsModels: null,
    documentTypes: {
        monotributo: ["dni", "monotributo", "historial_afip", "cuota_monotributo", "libre_deuda", "comprobantes_de_pago"],
        f931: ["f931", "libre_deuda", "comprobantes_de_pago"],
        chofer: ["dni", "art", "libreta_sanitaria", "linti", "registro", "seguro"],
        vehiculo: ["cedula_verde", "inscripcion_municipal", "seguro", "senasa", "vtv"],
    },
    empresasProveedoras: null,
    choferes: null,
    vehiculos: null,
    empresas: null,
    unidadesAlmacenamiento: [
        {
            nombre_unidad_almacenamiento: "canastos",
            factor_conversion: 1,
        },
        {
            nombre_unidad_almacenamiento: "placas",
            factor_conversion: 4,
        },
        {
            nombre_unidad_almacenamiento: "canastos azules",
            factor_conversion: 3,
        },
        {
            nombre_unidad_almacenamiento: "palet",
            factor_conversion: 10,
        },
        {
            nombre_unidad_almacenamiento: "heladera",
            factor_conversion: 20,
        },
        {
            nombre_unidad_almacenamiento: "cajas secos",
            factor_conversion: 2,
        },
    ],
    entidadesLogisticas: null,
    capacidadDeCargaDisponible: 0,
    storageUnits: [
        {
            id: 1,
            nombre_unidad_almacenamiento: "canasto",
            factor_conversion: 1,
        },
        {
            id: 2,
            nombre_unidad_almacenamiento: "placas",
            factor_conversion: 4,
        },
        {
            id: 3,
            nombre_unidad_almacenamiento: "pallet",
            factor_conversion: 10,
        },
        {
            id: 4,
            nombre_unidad_almacenamiento: "heladera",
            factor_conversion: 20,
        },
    ],
    rutas: null,
});
