// import isAutenticatedGuard from "./logistica-guard";

export default {
    name: "logistica",
    // beforeEnter: [ isAutenticatedGuard ],
    component: () => import(/* webpackChunkName: "LogisticaLayout" */ "@/modules/logistica/layouts/LogisticaLayout.vue"),
    children: [
        {
            path: "dashboard",
            name: "dashboard",
            component: () => import(/* webpackChunkName: "Dashboard" */ "@/modules/logistica/views/Dashboard.vue"),
        },
        {
            path: "empresas",
            name: "empresas",
            component: () => import(/* webpackChunkName: "Empresas" */ "../../../modules/logistica/views/Empresas.vue"),
        },
        {
            path: "choferes",
            name: "choferes",
            component: () => import(/* webpackChunkName: "choferes" */ "../../../modules/logistica/views/Choferes.vue"),
        },
        {
            path: "vehiculos",
            name: "vehiculos",
            component: () => import(/* webpackChunkName: "Vehiculos" */ "../../../modules/logistica/views/Vehiculos.vue"),
        },
        {
            path: "entidades-logisticas",
            name: "entidades-logisticas",
            component: () => import(/* webpackChunkName: "Vinculos" */ "../../../modules/logistica/views/Vinculos.vue"),
        },
        {
            path: "calendario-vencimientos",
            name: "calendario-vencimientos",
            component: () => import(/* webpackChunkName: "CalendarioVencimientos" */ "../../../modules/logistica/views/CalendarioVencimientos.vue"),
        },
        {
            path: "admision",
            name: "admision",
            component: () => import(/* webpackChunkName: "Admision" */ "../../../modules/logistica/views/Admision.vue"),
        },
        {
            path: "documentos",
            name: "documentos",
            component: () => import(/* webpackChunkName: "Documentos" */ "../../../modules/logistica/views/Documentos.vue"),
        },
        {
            path: "unidades-almacenaje",
            name: "unidades-almacenaje",
            component: () => import(/* webpackChunkName: "UnidadesAlmacenaje" */ "../../../modules/logistica/views/UnidadesAlmacenaje.vue"),
        },
        {
            path: "rutas",
            component: () => import(/* webpackChunkName: "rutas" */ "@/modules/logistica/views/Rutas.vue"),
            children: [
                {
                    path: "",
                    name: "rutas",
                    component: () => import(/* webpackChunkName: "rutas" */ "@/modules/logistica/components/Ruta/ActiveRoutes.vue"),
                },
                {
                    path: "editar-ruta/:id",
                    name: "editar-ruta",
                    component: () => import(/* webpackChunkName: "editar-ruta" */ "@/modules/logistica/components/Ruta/RouteForm.vue"),
                    props: (route) => {
                        return {
                            id: route.params.id,
                        };
                    },
                },
                {
                    path: "nueva-ruta",
                    name: "nueva-ruta",
                    component: () => import(/* webpackChunkName: "nueva-ruta" */ "@/modules/logistica/components/Ruta/RouteForm.vue"),
                },
            ],
        },
        {
            path: "entidad",
            name: "entidad",
            component: () => import(/* webpackChunkName: "CrearEntidad" */ "../../../modules/logistica/components/Entidad/CrearEntidad.vue"),
        },
    ],
};
